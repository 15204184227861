import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { StationSession } from './models/station-session';
import { StationReservationsV2Parameter } from './models/station-reservations-v2-parameter';
import { StationReservationsV2Response } from './models/station-reservations-v2-response';
import { StationStationsV2Parameter } from './models/station-stations-v2-parameter';
import { StationStationsV2Response } from './models/station-stations-v2-response';
import { StationVehiclesV2Parameter } from './models/station-vehicles-v2-parameter';
import { StationVehiclesV2Response } from './models/station-vehicles-v2-response';
import { ServicerProvider } from './servicer';

/**
 * ステーションV2サービスプロバイダー.
 */
@Injectable()
export class StationV2ServiceProvider extends ServicerProvider {
  private sessionSource: BehaviorSubject<StationSession> = new BehaviorSubject<StationSession>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): StationSession {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: StationSession) {
    this.sessionSource.next(session);
  }

  /**
   * 予約一覧取得.
   * ステーションでの予約一覧を取得する。
   *
   * @param parameter StationReservationsV2Parameter
   * @returns Observable<StationReservationsV2Response>
   */
  reservations(parameter: StationReservationsV2Parameter): Observable<StationReservationsV2Response> {
    return this.post<string, StationReservationsV2Response>(
      '/v2/station/reservations',
      this.parameterToBody(parameter));
  }

  /**
   * ステーション一覧取得.
   *  
   * @param parameter StationStationsV2Parameter
   * @returns Observable<StationStationsV2Response> 
   */
  stations(parameter: StationStationsV2Parameter): Observable<StationStationsV2Response> {
    return this.post<string, StationStationsV2Response>(
      '/v2/station/stations',
      this.parameterToBody(parameter));
  }

  /**
   * 車両一覧取得.
   *  
   * @param parameter StationVehiclesV2Parameter
   * @returns Observable<StationVehiclesV2Response> 
   */
  vehicles(parameter: StationVehiclesV2Parameter): Observable<StationVehiclesV2Response> {
    return this.post<string, StationVehiclesV2Response>(
      '/v2/station/vehicles',
      this.parameterToBody(parameter));
  }

  /**
 * 時刻表取得.
 *  
 * @param parameter StationVehiclesV2Parameter
 * @returns Observable<StationVehiclesV2Response> 
 */
    timeschedule(): Observable<string> {
    return this.getText<string>(
      '/v2/image?name=system/opening_hours.txt'
    );
  }
}
