import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {IonicModule} from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { CustomCameraComponent } from './custom-camera';

@NgModule({
  declarations: [
    CustomCameraComponent,
  ],
  imports: [
    IonicModule,
    CommonModule,
  ],
  exports: [
    CustomCameraComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CustomCameraComponentModule {}
