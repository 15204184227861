/**
 * API Result タイプ.
 */
export const ApiResultType = {
  /** 成功(1). */
  SUCCESS: 1,
  /** エラー(0). */
  ETC_ERROR: 0,
  /** データなし(2). */
  NOT_FOUND: 2
};

export type ApiResultType = typeof ApiResultType[keyof typeof ApiResultType];
