import { Injectable } from '@angular/core';
import { StationForSearch } from './servicer/models/stationForSearch';


@Injectable()
export class CsvLoader {

  readonly syllabary = [
    ['ア','イ','ウ','エ','オ'],
    ['カ','キ','ク','ケ','コ','ガ','ギ','グ','ゲ','ゴ'],
    ['サ','シ','ス','セ','ソ','ザ','ジ','ズ','ゼ','ゾ'],
    ['タ','チ','ツ','テ','ト','ダ','ヂ','ヅ','デ','ド'],
    ['ナ','ニ','ヌ','ネ','ノ'],
    ['ハ','ヒ','フ','ヘ','ホ','バ','ビ','ブ','ベ','ボ','パ','ピ','プ','ペ','ポ'],
    ['マ','ミ','ム','メ','モ'],
    ['ヤ','ユ','ヨ','',''],
    ['ラ','リ','ル','レ','ロ'],
    ['ワ','ヲ','ン','','']
  ];

  readonly CODE_ROW_COLUMN = 0; 
  readonly TYPE_ROW_COLUMN = 1;
  readonly NAME_ROW_COLUMN = 2;
  readonly YOMI_ROW_COLUMN = 3;
  readonly DISTRICT_L_COLUMN = 4;
  readonly DISTRICT_S_COLUMN = 5;
  readonly LAT_ROW_COLUMN = 6; 
  readonly LON_ROW_COLUMN = 7; 
  readonly ADDRESS_ROW_COLUMN = 8; 
  readonly NICKNAME_COLUMN = 10;
  readonly NICKNAME_KANA_COLUMN = 11;
  readonly KEYWORD_COLUMN = 12;
  readonly KEYWORD_KANA_COLUMN = 13;

  static readonly TYPE_MAIN = 1;
  static readonly TYPE_CENTRAL_KEYWORD = 2;
  static readonly TYPE_CENTRAL_NOT_KEYWORD = 3;
  static readonly TYPE_PERIPHERAL = 4;
  
  public stations = new Array<StationForSearch>();
  public stationsForSearch = new Array<StationForSearch>();
  public stationsForDoSearch = new Array<StationForSearch>();

  private nicknameList = new Array<Nickname>(); 
  private keywordList = new Array<Keyword>();
  
  public getSpotFromInitial(word: string[]): Array<Nickname>
  {    
    return this.nicknameList.filter(nickname => word.find((word) => word === nickname.yomi.charAt(0)));
  }

  public getKeywordFromInitial(word: string[]): Array<Keyword>
  {
    return this.keywordList.filter(keyword => word.find((word) => word === keyword.yomi.charAt(0)));
  }

  public getStation(code:string): StationForSearch{
    return this.stationsForSearch.find(spot => code === spot.code);
  }

  public getNearDoSpot(puType:Number, doType:Number, lat: number, lon: number): StationForSearch {
    let res = null;
    let saveDist = -1;
    for(let i = 0; i < this.stationsForDoSearch.length; i++) {
      if(puType === 2 && (doType === 3 || doType === 4) && this.stationsForDoSearch[i].type !== 1) continue;
      if((puType === 3 || puType === 4) && (doType === 3 || doType === 4) && this.stationsForDoSearch[i].type !== 1) continue;
      
      if(res == null) {
        res = this.stationsForDoSearch[i];
        saveDist = Math.sqrt(Math.pow(lat-this.stationsForDoSearch[i].lat, 2) + Math.pow(lon-this.stationsForDoSearch[i].lon, 2));
      } 
      else {
        let dist = Math.sqrt(Math.pow(lat-this.stationsForDoSearch[i].lat, 2) + Math.pow(lon-this.stationsForDoSearch[i].lon, 2));
        if(dist < saveDist) {
          saveDist = dist;
          res = this.stationsForDoSearch[i];
        }
      }
    }
    return res;
  }

  public import(csvdata){
    this.stations = csvdata['stations'];
    this.stationsForSearch = csvdata['stationsForSearch'];
    this.stationsForDoSearch = csvdata['stationsForDoSearch'];
    this.nicknameList = csvdata['nicknameList']; 
    this.keywordList = csvdata['keywordList'];
 }

}

export interface Nickname {
  name:string;
  yomi:string;
  station:StationForSearch;
}

export interface Keyword {
  word:string;
  yomi:string;
  nickName:Nickname[];
}