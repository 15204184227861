import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RetryGuestLoginFlagService {
  private isRetry = true;
  constructor(
    ) { }
    
    /**
     * アプリ初回起動時のみ、API（guestLogin）の実行に失敗した場合リトライ処理を実行するフラグを返す
     * @returns true: リトライ処理を実行する　false:　リトライ処理を実行しない
     */
    public getFlag(): boolean{
      return this.isRetry;
    }
    
    /**
     * アプリ初回起動時後に、API（guestLogin）の実行完了時にフラグをfalseに切り替える
     * @param flag 
     */
    public setFlag(flag: boolean): void{
      this.isRetry = flag;
    }
}
