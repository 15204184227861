import { Injectable } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Platform } from '@ionic/angular';
import { Observable, BehaviorSubject, of, interval } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

declare var faceapi: any;

/**
 * カメラのプロバイダー.
 */
@Injectable()
export class CameraProvider {
  /** 顔検出. */
  isFaceDetected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /** タイマーカウンター. */
  timerCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  /** ビデオの表示. */
  isShowVideo: boolean = false;

  /** ビデオのスタイル. */
  videoStyle;

  /** 画像の表示. */
  isShowCapture: boolean = false;

  /** 検出画像の表示. */
  isShowDetectCapture: boolean = false;

  /** 検出結果の表示. */
  isShowDetectResult: boolean = false;

  /**
   * メディアの種類
   * https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia
   */
  private mediaStreamConstraints: MediaStreamConstraints;

  /** カメラの開始状態. */
  private cameraStarted: boolean = false;

  /** ストリーム. */
  private stream: MediaStream;

  /** videoエレメント. */
  private video: HTMLVideoElement;

  /** 画像取得用キャンバスエレメント. */
  private capture: HTMLCanvasElement;

  /** 画像取得用レンダリングコンテキスト. */
  private captureContext: CanvasRenderingContext2D;

  /** 検出画像取得用キャンバスエレメント. */
  private detectCapture: HTMLCanvasElement;

  /** 検出画像取得用レンダリングコンテキスト. */
  private detectCaptureContext: CanvasRenderingContext2D;

  /** 検出結果描画用キャンバスエレメント. */
  private detectResult: HTMLCanvasElement;

  /** 検出結果描画用レンダリングコンテキスト. */
  private detectResultContext: CanvasRenderingContext2D;

  /** 検出の設定. */
  private faceDetectSetting = environment.setting.camera.faceDetect;

  private detectorOptions: any;

  private height: number;
  private width: number;
  private facingMode: string;
  private scaleX: number;
  private scaleY: number;
  private rotateData: number;
  private angle: number;
  private translateX: number;
  private translateY: number;
  private dx: number;
  private dy: number;

  constructor(
    public androidPermissions: AndroidPermissions,
    public platform: Platform) {
  }

  /**
   * 顔検出の設定を初期化します.
   */
  initFaceDetect(): Observable<void> {
    this.faceDetectSetting = environment.setting.camera.faceDetect;
    this.isShowDetectCapture = this.faceDetectSetting.showCaptureAlways;
    this.isShowDetectResult = this.faceDetectSetting.showResultAlways;

    this.isFaceDetected$.next(true);

    // VUP: by Copilot
    return new Observable(observer => {
      this.setFaceApiDetectorOptions().then(() => {
        observer.next(null);
        observer.complete();
      });
    });
  }

  /**
   * カメラの設定を初期化します.
   */
  initCamera(): Observable<void> {
    this.isShowVideo = environment.setting.camera.showAlways;
    this.setVideoStyle(
      environment.setting.camera.rotate,
      environment.setting.camera.scaleX,
      environment.setting.camera.scaleY,
      environment.setting.camera.showTop,
      environment.setting.camera.showLeft,
      environment.setting.camera.showWidth);

    if (environment.setting.camera.startAlways) {
      return this.startCameraSetting().pipe(map(() => null));
    }

    return of(null);
  }

  /**
   * エレメントを設定します.
   * 
   * @param video ビデオHTMLエレメント
   * @param capture 画像HTMLエレメント
   * @param detectCapture 検出画像HTMLエレメント
   * @param detect 検出結果HTMLエレメント
   */
  setElement(
    video: HTMLVideoElement,
    capture: HTMLCanvasElement,
    detectCapture: HTMLCanvasElement,
    detectResult: HTMLCanvasElement) {
    this.video = video;

    this.capture = capture;
    this.captureContext = this.capture.getContext('2d');

    this.detectCapture = detectCapture;
    this.detectCaptureContext = this.detectCapture.getContext('2d');

    this.detectResult = detectResult;
    this.detectResultContext = this.detectResult.getContext('2d');
  }

  /**
   * カメラの使用を開始します.
   */
  start(): Observable<MediaStream> {
    return this.startCameraSetting();
  }

  /**
   * 顔検出を開始します.
   */
  startFaceDetect(): Observable<MediaStream> {
    if (!this.faceDetectSetting.enabled) {
      return of(null);
    }

    return this.startCameraSetting();
  }

  /**
   * カメラタイマーを開始します.
   * 
   * @param time タイマー（単位：ms）
   */
  startTimer(time: number): Observable<number> {
    let counter = Math.ceil(time / 1000);
    this.timerCounter$.next(counter);

    // VUP:
    return interval(1000)
      .pipe(tap(() => this.timerCounter$.next(--counter)));
    }

  /**
   * ビデオのスタイルを指定します.
   * 
   * @param rotate 回転角度
   * @param scaleX X方向の縮尺比率
   * @param scaleY Y方向の縮尺比率
   * @param top 上からの表示位置（単位：px）
   * @param left 左からの表示位置（単位：px）
   * @param width 表示幅（単位：px）
   */
  private setVideoStyle(rotate: number, scaleX: number, scaleY: number, top: number, left: number, width: number) {
    this.videoStyle = { 'transform': `rotate(-${rotate}deg) scaleX(${scaleX}) scaleY(${scaleY})`, 'top': `${top}px`, 'left': `${left}px`, 'width': `${width}px` };
  }

  /**
   * ビデオを表示をします.
   */
  showVideo() {
    this.showSwitchVideo(true);
  }

  /**
   * ビデオを非表示にします.
   */
  hideVideo() {
    this.showSwitchVideo(false);
  }

  /**
   * ビデオの表示を切り替えます.
   * 
   * @param show 表示
   */
  private showSwitchVideo(show: boolean) {
    if (environment.setting.camera.showAlways) {
      this.isShowVideo = true;
      return;
    }

    this.isShowVideo = show;
  }

  /**
   * キャプチャを表示をします.
   */
  showCapture() {
    this.isShowCapture = true;
  }

  /**
   * キャプチャを非表示にします.
   */
  hideCapture() {
    this.isShowCapture = false;
  }

  /**
   * カメラの使用を停止します.
   */
  stop() {
    this.clearCameraAndCanvas(!environment.setting.camera.startAlways);
  }

  /**
   * カメラの使用を強制停止します.
   */
  forceStop() {
    this.clearCanvas(this.detectCapture, this.detectCaptureContext);
    this.clearCanvas(this.detectResult, this.detectResultContext);

    this.isShowDetectCapture = false;
    this.isShowDetectResult = false;

    this.clearCameraAndCanvas(true);
  }

  /**
   * カメラとキャンバスをクリアします.
   * 
   * @param stop カメラ停止
   */
  private clearCameraAndCanvas(stop: boolean) {
    this.clearCanvas(this.capture, this.captureContext);

    this.isShowVideo = false;
    this.isShowCapture = false;

    if (stop) {
      this.stopCamera();
    }
  }

  private startCameraSetting(): Observable<MediaStream> {
    if (this.cameraStarted) {
      return of(this.stream);
    }

    if (!environment.setting.camera.enabled) {
      return of(null);
    }

    this.cameraStarted = false;

    this.height = environment.setting.camera.height;
    this.width = environment.setting.camera.width;
    this.facingMode = environment.setting.camera.facingMode;
    this.scaleX = environment.setting.camera.scaleX;
    this.scaleY = environment.setting.camera.scaleY;
    this.rotateData = environment.setting.camera.rotate;

    this.angle = this.rotateData * Math.PI / 180;
    this.translateX = this.width / 2;
    this.translateY = this.height / 2;
    this.dx = this.translateX * -1;
    this.dy = this.translateY * -1;

    return this.startCamera({
      /** audio : OFF */
      audio: false,
      /** video : ON */
      video: {
        facingMode: this.facingMode,
        width: this.width,
        height: this.height
      }
    })
      .pipe(
        tap((stream) => {
          this.video.srcObject = stream;
          this.video.play();
          this.play();
        }));
  }

  /**
   * 推論が高速な顔検出。バウンディングボックスと精度を取得します.
   */
  private async setFaceApiDetectorOptions() {
    if (!this.faceDetectSetting.enabled) {
      return;
    }

    if (this.platform.is('cordova')) {
      // cordova だと file://スキーマ が使用できないため、loadFromUriを使用
      await faceapi.nets.tinyFaceDetector.loadFromUri('https://raw.githubusercontent.com/justadudewhohacks/face-api.js/master/weights');
    } else {
      await faceapi.nets.tinyFaceDetector.loadFromUri('assets/plugin/face-api/weights');
    }

    // オプション
    this.detectorOptions = new faceapi.TinyFaceDetectorOptions();
  }

  private async play() {
    if (!this.cameraStarted) {
      return;
    }

    await this.faceDetect();

    setTimeout(() => this.play(), this.faceDetectSetting.interval);
  }

  /**
   * キャプチャデータを設定します.
   *
   * @param capture 画像取得用キャンバスエレメント
   * @param captureContext 画像取得用レンダリングコンテキスト
   */
  private setCaptureData(
    capture: HTMLCanvasElement,
    captureContext: CanvasRenderingContext2D) {
    capture.width = this.width;
    capture.height = this.height;

    captureContext.translate(this.translateX, this.translateY);
    captureContext.scale(this.scaleX, this.scaleY);

    captureContext.rotate(this.angle);
    captureContext.drawImage(this.video, this.dx, this.dy, this.width, this.height);
  }

  /**
   * Blob型のキャプチャを取得します.
   */
  getBlobCapture(): Blob {
    this.setCaptureData(this.capture, this.captureContext);

    return this.base64toBlob(this.capture.toDataURL());
  }

  /**
   * 顔を検出します.
   */
  private async faceDetect() {
    if (!this.faceDetectSetting.enabled) {
      return;
    }

    try {
      this.setCaptureData(this.detectCapture, this.detectCaptureContext);

      // 画像内で最も信頼度の高い顔を検出
      // https://justadudewhohacks.github.io/face-api.js/docs/index.html
      const detection = await faceapi.detectSingleFace(this.detectCapture, this.detectorOptions);
      await this.drawDetect(detection);
  
      this.setFaceDetected(this.isFaceDetected(detection));
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * キャンバスをクリアします.
   * 
   * @param context CanvasRenderingContext2D
   */
  private clearCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    context.save();
    context.setTransform(1, 0, 0, 1, 0, 0);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.restore();
  }

  /**
   * 検出結果を描画します.
   *
   * @param detection 検出結果
   */
  private async drawDetect(detection: any) {
    if (!this.isShowDetectResult) {
      return;
    }

    if (detection) {
      const dims = await faceapi.matchDimensions(this.detectResult, this.detectCapture, true);
      await faceapi.draw.drawDetections(this.detectResult, faceapi.resizeResults(detection, dims));
    } else {
      this.clearCanvas(this.detectResult, this.detectResultContext);
    }
  }

  /**
   * 検出成功/失敗を設定します.
   * 
   * @param isFaceDetected 今回の検出結果
   */
  private setFaceDetected(isFaceDetected: boolean) {
    this.isFaceDetected$.next(isFaceDetected);
  }

  /**
   * 検出結果が閾値内か判定します.
   * 
   * @param detection 検出結果
   * @returns true : 閾値内 / false : 閾値オーバー
   */
  private isFaceDetected(detection: any): boolean {
    if (!detection) {
      return false;
    }

    const threshold = this.faceDetectSetting.threshold;

    if (detection.score < threshold.score) {
      return false;
    }

    const box = threshold.box;

    if (this.isRange(detection.box.height, box.height.min, box.height.max)) {
      return false;
    }

    if (this.isRange(detection.box.width, box.width.min, box.width.max)) {
      return false;
    }

    if (this.isRange(detection.box.top, box.top.min, box.top.max)) {
      return false;
    }

    if (this.isRange(detection.box.left, box.left.min, box.left.max)) {
      return false;
    }

    return true;
  }

  /**
   * 値が範囲内にあるかどうかか判定します.
   * 
   * @param value 値
   * @returns true : 範囲内 / false : 範囲外
   */
  private isRange(value: number, min: number, max: number): boolean {
    return (value < min || max < value);
  }

  /**
   * カメラの使用を開始します.
   */
  private startCamera(mediaStreamConstraints: MediaStreamConstraints): Observable<MediaStream> {
    this.mediaStreamConstraints = mediaStreamConstraints;

    // VUP: by Copilot
    return new Observable(observer => {
      this.requestPermissionForCamera().then(() => {
        this.getUserMedia().subscribe(stream => {
          observer.next(stream);
          observer.complete();
        });
      });
    });
  }

  /**
   * カメラの使用を停止します.
   */
  private stopCamera() {
    if (!this.cameraStarted) {
      return;
    }

    this.stream.getTracks().forEach((track) => {
      if (track.readyState === 'live' && track.kind === 'video') {
        track.stop();
        this.cameraStarted = false;
      }
    });
  }

  /**
   * MediaStream を取得します.
   */
  private getUserMedia(): Observable<MediaStream> {
    this.cameraStarted = false;

    // VUP: by Copilot
    return new Observable(observer => {
      navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints).then((stream) => {
        this.cameraStarted = true;
        this.stream = stream;
        observer.next(stream);
        observer.complete();
      });
    });
  }

  /**
   * カメラの許可をリクエストします.
   */
  private async requestPermissionForCamera() {
    if (!this.platform.is('cordova')) {
      // PC上のブラウザで実行されている場合、処理を終了する
      return;
    }

    try {
      const result = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA);

      if (!result.hasPermission) {
        await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
      }
    } catch (e) {
      await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA);
    }
  }

  /**
   * bas64 文字列をバイナリデータに変換する
   *
   * @param {string} base64 バイナリデータを base64 エンコードして更に文字列化した文字列
   * @returns {Blob} 引数の文字列をバイナリに戻したバイナリデータ
   * @memberof AggregateMonthlyComponent
   * @description
   *  ZIP ファイルへの変換のみ対応している
   * @see
   *  https://developer.mozilla.org/ja/docs/Web/API/WindowBase64/atob
   *  https://developer.mozilla.org/ja/docs/Web/API/Blob
   *  https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_objects/Uint8Array
   */
  private  base64toBlob(base64: string): Blob {
    // カンマで分割して以下のようにデータを分ける
    // tmp[0] : データ形式（data:image/png;base64）
    // tmp[1] : base64データ（iVBORw0k～）
    const tmp = base64.split(',');
    // base64データの文字列をデコード
    const data = atob(tmp[1]);
    // tmp[0]の文字列（data:image/png;base64）からコンテンツタイプ（image/png）部分を取得
    const mime = tmp[0].split(':')[1].split(';')[0];
    //  1文字ごとにUTF-16コードを表す 0から65535 の整数を取得
    const buf = new Uint8Array(data.length);
    for (var i = 0; i < data.length; i++) {
      buf[i] = data.charCodeAt(i);
    }
    // blobデータを作成
    const blob = new Blob([buf], { type: mime });

    return blob;
  }
}
