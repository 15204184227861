import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageWrapperService {
  private localStorage: Storage
  constructor(
    private storage: Storage
  ) { }


  public async init() {
    this.localStorage = await this.storage.create();
  }

  public setStorage(key: string, value: string): void {
    this.localStorage.set(key, value);
  }

  public getStorage(key: string): Promise<string> {
    return new Promise((resolve) => {
      resolve(this.localStorage.get(key));
    });
  }

  public removeStorage(key: string): void {
    this.localStorage.remove(key);
  }
}
