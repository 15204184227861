import { Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CameraProvider } from '../../providers/camera/camera';
import { BehaviorSubject } from 'rxjs';

import { delay } from 'rxjs/operators';

@Component({
  selector: 'custom-camera',
  templateUrl: 'custom-camera.html',
  styleUrls: ['custom-camera.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomCameraComponent {
  viewChange: string;
  /** ビデオ. */
  @ViewChild('video') videoElm: ElementRef;

  /** 画像. */
  @ViewChild('capture') captureElm: ElementRef;

  /** 検出画像. */
  @ViewChild('detectCapture') detectCaptureElm: ElementRef;

  /** 検出結果. */
  @ViewChild('detectResult') detectResultElm: ElementRef;

  constructor(
    public platform: Platform,
    public camera: CameraProvider
  ) { }

  ngOnInit() {
    (async () => {
      this.viewChange = '';
      await delay(100);
      this.viewChange = 'contsStatusShow';
    })();
    this.camera.setElement(
      this.videoElm.nativeElement,
      this.captureElm.nativeElement,
      this.detectCaptureElm.nativeElement,
      this.detectResultElm.nativeElement);
  }

  ngOnDestroy() {
    this.camera.stop();
  }

  get isHideVideo(): boolean {
    return !this.camera.isShowVideo;
  }

  get videoStyle() {
    return this.camera.videoStyle;
  }

  get isHideCapture(): boolean {
    return !this.camera.isShowCapture;
  }

  get isHideDetectCapture(): boolean {
    return !this.camera.isShowDetectCapture;
  }

  get isHideDetectResult(): boolean {
    return !this.camera.isShowDetectResult;
  }

  get isHideTimerCounter(): boolean {
    return this.camera.timerCounter$.value === 0;
  }

  get timerCounter(): BehaviorSubject<number> {
    return this.camera.timerCounter$;
  }
}
