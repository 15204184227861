import { Component,ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LoadingType } from '../../providers/loading/types/loading-type';
import { LoadingProvider } from '../../providers/loading/loading';

import { delay } from 'rxjs/operators';

@Component({
  selector: 'custom-loading',
  templateUrl: 'custom-loading.html',
  styleUrls: ['custom-loading.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomLoadingComponent {
  viewChange: string;
  readonly constantLoadingType = LoadingType;

  constructor(
    private loading: LoadingProvider
  ) { }

  get isLoading(): BehaviorSubject<boolean> {
    return this.loading.isLoading$;
  }

  get loadingType(): LoadingType {
    return this.loading.loadingType;
  }
  ngOnInit() {
    (async () => {
      this.viewChange = '';
      await delay(100);
      this.viewChange = 'contsStatusShow';
    })();
  }
}
