import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { UserArrivedV2Parameter } from './models/user-arrived-v2-parameter';
import { UserArrivedV2Response } from './models/user-arrived-v2-response';
import { UserDispatchCancelParameter } from './models/user-dispatch-cancel-parameter';
import { UserDispatchCancelResponse } from './models/user-dispatch-cancel-response';
import { UserDispatchConfirmationV2Parameter } from './models/user-dispatch-confirmation-v2-parameter';
import { UserDispatchConfirmationV2Response } from './models/user-dispatch-confirmation-v2-response';
import { UserDispatchRequestV2Parameter } from './models/user-dispatch-request-v2-parameter';
import { UserDispatchRequestV2Response } from './models/user-dispatch-request-v2-response';
import { UserFaceLoginV2Parameter } from './models/user-face-login-v2-parameter';
import { UserFaceLoginV2Response } from './models/user-face-login-v2-response';
import { UserLoginV2Parameter } from './models/user-login-v2-parameter';
import { UserLoginV2Response } from './models/user-login-v2-response';
import { UserGuestLoginV2Parameter } from './models/user-guest-login-v2-parameter';
import { UserGuestLoginV2Response } from './models/user-guest-login-v2-response';
import { UserSession } from './models/user-session';
import { UserVehicleSearchV2Parameter } from './models/user-vehicle-search-v2-parameter';
import { UserVehicleSearchV2Response } from './models/user-vehicle-search-v2-response';
import { ServicerProvider } from './servicer';

import { UserDoStationParameter } from './models/user-do-station-codes-v2-api-parameter';
import { UserDoStationCodesV2ApiResponse } from './models/user-do-station-codes-v2-api-response';
import { UserStationsV2Response } from './models/user-stations-v2-response';

/**
 * ユーザV2サービスプロバイダー.
 */
@Injectable()
export class UserV2ServiceProvider extends ServicerProvider {
  private sessionSource: BehaviorSubject<UserSession> = new BehaviorSubject<UserSession>(null);

  constructor(
    public http: HttpClient
  ) {
    super(http);
  }

  /**
   * セッションを取得する.
   */
  getSession(): UserSession {
    return this.sessionSource.getValue();
  }

  /**
   * セッションを削除する.
   */
  deleteSession() {
    this.sessionSource.next(null);
  }

  /**
   * セッションを保存する.
   */
  saveSession(session: UserSession) {
    this.sessionSource.next(session);
  }

  /**
   * ユーザ到着.
   * 
   * @param parameter UserArrivedV2Parameter
   * @returns Observable<UserArrivedV2Response> 
   */
  arrived(parameter: UserArrivedV2Parameter): Observable<UserArrivedV2Response> {
    return this.post<string, UserArrivedV2Response>(
      '/v2/user/arrived',
      this.parameterToBody(parameter));
  }

  /**
   * 配車キャンセル.
   * 
   * @param parameter userDispatchCancelParameter
   * @returns Observable<userDispatchCancelResponse>
   */
  dispatchCancel(parameter: UserDispatchCancelParameter): Observable<UserDispatchCancelResponse> {
    return this.post<string, UserDispatchCancelResponse>(
      '/v2/user/dispatchCancel',
      this.parameterToBody(parameter));
  }

  /**
   * ユーザステータス確認.
   * 
   * @param parameter UserDispatchConfirmationV2Parameter
   * @returns Observable<UserDispatchConfirmationV2Response>
   */
  dispatchConfirmation(parameter: UserDispatchConfirmationV2Parameter): Observable<UserDispatchConfirmationV2Response> {
    return this.post<string, UserDispatchConfirmationV2Response>(
      '/v2/user/dispatchConfirmation',
      this.parameterToBody(parameter));
  }

  /**
   * 配車指示.
   * 予約を確定して配車指示を行う。
   *
   * @param parameter UserDispatchRequestV2Parameter
   * @returns Observable<UserDispatchRequestV2Response>
   */
  dispatchRequest(parameter: UserDispatchRequestV2Parameter): Observable<UserDispatchRequestV2Response> {
    return this.post<string, UserDispatchRequestV2Response>(
      '/v2/user/dispatchRequest',
      this.parameterToBody(parameter));
  }

  /**
   * 顔認証.
   * 顔写真でログイン認証を実行する。
   *
   * @param parameter UserFaceLoginV2Parameter
   * @returns Observable<UserFaceLoginV2Response>
   */
  faceLogin(parameter: UserFaceLoginV2Parameter): Observable<UserFaceLoginV2Response> {
    const formData = new FormData();
    formData.append('file', parameter.file);

    return this.postFormData<UserFaceLoginV2Response>(
      '/v2/user/faceLogin',
      formData);
  }

  /**
   * ユーザログイン.
   * 指定したログインID・パスワードでログイン認証を実行する。
   *
   * @param parameter UserLoginV2Parameter
   * @returns Observable<UserLoginV2Response>
   */
  login(parameter: UserLoginV2Parameter): Observable<UserLoginV2Response> {
    return this.post<string, UserLoginV2Response>(
      '/v2/user/login',
      this.parameterToBody(parameter));
  }

  /**
   * 車両検索.
   * 配車可能な車両の検索を行い、結果を取得する。
   * この段階ではユーザの配車予約は確定していない。
   *
   * @param parameter UserVehicleSearchV2Parameter
   * @returns Observable<UserVehicleSearchV2Response>
   */
  vehicleSearch(parameter: UserVehicleSearchV2Parameter): Observable<UserVehicleSearchV2Response> {
    return this.post<string, UserVehicleSearchV2Response>(
      '/v2/user/vehicleSearch',
      this.parameterToBody(parameter));
  }

  /**
   * ゲストユーザログイン.
   *
   * @param parameter UserGuestLoginV2Parameter
   * @returns Observable<UserGuestLoginV2Response>
   */
   guestLogin(parameter: UserGuestLoginV2Parameter): Observable<UserGuestLoginV2Response> {
    return this.post<string, UserGuestLoginV2Response>(
      '/v2/user/guestLogin',
      this.parameterToBody(parameter));
  }


  /**
   * ステーション降車地一覧取得.
   *  
   * @param parameter StationStationsV2Parameter
   * @returns Observable<StationStationsV2Response> 
   */
   doStationCode(User_id:number,puCode: string): Observable<UserDoStationCodesV2ApiResponse> {
     var parameter:UserDoStationParameter = {
      userId:User_id,
      puStationCode:puCode
    };
    return this.post<string, UserDoStationCodesV2ApiResponse>(
      '/v2/user/doStationCodes',
      this.parameterToBody(parameter));
  }
 
    /**
   * ステーション降車地一覧取得.
   *  
   * @param parameter StationStationsV2Parameter
   * @returns Observable<StationStationsV2Response> 
   */
     stations(User_id:number): Observable<UserStationsV2Response> {
      var parameter = {
        user_id:User_id,
     };
     return this.post<string, any>(
       '/v2/user/stations',
       this.parameterToBody(parameter));
   }
}
