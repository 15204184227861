export const environment = {
  /* 環境種別 */
  type: 'spoke',
  /** アプリバージョン */
  appVersion: '1.2',
  /**バージョンファイルパス  */
  addressVersionUrl: 'https://nissan-mslab-dev-na.com/reserve/v2/image?name=system/namie-version.json',
  /** PWA更新中タイムアウト(ms) */
  pwaUpdatingTimeout: 1000 * 15,
  /** PWA関連のモーダル最短表示時間(ms) */
  pwaDisplayTimeout: 1000 * 2,
  /** PWA更新確認中の操作無効化(true:操作可, false:操作不可) */
  operationDuringPwaUpdate: false,

  setting: {
    /** サービサーURL. */
    servicerApiUrl: 'https://nissan-mslab-dev-na.com/reserve',
    /** リソースS3バケットURL. */
    resourceS3BucketUrl: 'https://resources.ad-uploader.nissan-mslab-dev-na.com',
    /** 言語. */
    language: 'ja',
    /** ステーションコード. */
    stationCode: '',
    /** 行先制限. */
    showAnotherdest: false,
    /** 自動起動（保存した設定でアプリが起動）. */
    autostart: true,
    /** エリアインデックス. */
    areaIndex: 1,
    /** 現在の機体番号インデックス */
    deviceNumberIndex: 0,
    /** プレイリストの更新時刻 */
    playlistUpdateTime: '07:00',
    /** プレイリスト画像表示継続時間(単位:秒 / > 3) */
    playlistNextImageInterval: 10,
    /** プレイリスト通信リトライ回数 */
    playlistFetchRetryCount: 3,
    /** プレイリスト通信リトライ間隔（単位：ms）. */
    playlistFetchRetryInterval: 180000,
    /** 通信中ポップアップの固定表示時間（単位：ms）. */
    networkingPopupDispTime: 2000,
    /** 顔認証するまでの待ち時間（単位：ms）. */
    faceLoginWaitingTime: 5000,
    /** 待ち受け画面の表示待ち時間（単位：ms）. */
    showWaitingScreenWaitingTime: 300000,
    /** 予約完了後の予約詳細表示画面の表示待ち時間（単位：ms）. */
    reservationWaitingTime: 10000,
    /** 予約キャンセル画面の待ち時間（単位：ms）. */
    cancelMessageWaitingTime: 3000,
    /** 車両が近くに到着したか判定する時間（単位：ms）. */
    vehicleArrivalIsNearTime: 60000,
    /** 予約一覧を取得する間隔（単位：ms）. */
    reservationsGetInterval: 1000,
    /** アプリ状態監視の間隔（単位：ms）. */
    appStateMonitoringInterval: 5000,
    /** サーバーキャッシュ自動更新の間隔. */
    serverCacheUpdateMode: 0,
    /** デフォルト乗車人数. */
    defaultRideCount: 1,
    /** 最小乗車人数. */
    minRideCount: 1,
    /** 最大乗車人数. */
    maxRideCount: 8,
    /** SS: 焼付き防止待機時間 (秒) . */
    waitScreenSaverTime: 20,
    /** カメラ. */
    camera: {
      /** カメラを使用. */
      enabled: true,
      /** カメラを常に起動. */
      startAlways: true,
      /** カメラを常に表示. */
      showAlways: false,
      /** カメラの上からの表示位置（単位：px）. */
      showTop: -100,
      /** カメラの左からの表示位置（単位：px）. */
      showLeft: -70,
      /** カメラの表示幅（単位：px）. */
      showWidth: 1200,
      /** カメラの解像度の縦幅（単位：px）. */
      height: 640,
      /** カメラの解像度の横幅（単位：px）. */
      width: 640,
      /** カメラのフロント（user）とリア（environment）. */
      facingMode: 'user',
      /** カメラの回転角度（反時計周り）（単位：度）. */
      rotate: 90,
      /** カメラの左右反転（1:反転なし, -1:反転あり）. */
      scaleX: 1,
      /** カメラの上下反転（1:反転なし, -1:反転あり）. */
      scaleY: 1,
      /** 顔検出. */
      faceDetect: {
        /** 有効. */
        enabled: true,
        /** 検出画像を常に表示. */
        showCaptureAlways: false,
        /** 検出結果を常に表示. */
        showResultAlways: false,
        /** 検出間隔（単位：ms）. */
        interval: 1000,
        /**
         * 閾値.
         * 閾値を満たす場合、顔検出したとみなす.
         */
        threshold: {
          /**
           * スコア.
           * 有効な範囲 : 0-1
           */
          score: 0.0,
          /** Box. */
          box: {
            /**
             * 有効な範囲 : 0-camera.height
             */
            height: {
              min: 0,
              max: 640
            },
            /**
             * 有効な範囲 : 0-camera.width
             */
            width: {
              min: 0,
              max: 640
            },
            /**
             * 有効な範囲 : 0-camera.height
             */
            top: {
              min: 0,
              max: 640
            },
            /**
             * 有効な範囲 : 0-camera.width
             */
            left: {
              min: 0,
              max: 640
            }
          }
        }
      }
    }
  },
  /** エリアリスト. */
  areas: [
    {
      name: 'NATC'
    },
    {
      name: '浪江'
    }
  ],
  stations: {
    /** 道の駅なみえ. */
    NH0101: { furigana: 'みちのえきなみえ' },
    /** JR浪江駅. */
    NH0103: { furigana: 'JRなみええき' },
    /** 浪江郵便局. */
    NH0104: { furigana: 'なみえゆうびんきょく' },
    /** イオン浪江店. */
    NH0105: { furigana: 'いおんなみえてん' },
    /** 浪江町役場. */
    NH0106: { furigana: 'なみえまちやくば' },
    /** スポーツセンター. */
    NH0113: { furigana: 'すぽーつせんたー' },
    /** 新町ふれあい広場. */
    NH0114: { furigana: 'しんまちふれあいひろば' }
  },
  /** 表示したい停留所のコード */
  viewStationsCodes:[
    /** 道の駅なみえ. */
    'NH0101',
    /** JR浪江駅. */
    'NH0103',
    /** イオン浪江店. */
    'NH0105',
    /** 浪江町役場. */
    'NH0106'
  ],
  /** ステーションに設定できない停留所のコード */
  cannotSetStationCodes:[
    /** イオン浪江店. */
    'NH0105',
  ],
  /**  検索ボタンタップ時の停留所名リストのデフォルトの値　*/
  defaultStationNames:[
    { code: 'NH0103', name: 'JR浪江駅'},
    { code: 'NH0104', name: '浪江郵便局'},
    { code: 'NH0114', name: '新町ふれあい広場'},
    { code: 'NH0106', name: '浪江町役場'},
    { code: 'NH0105', name: 'イオン浪江店'},
    { code: 'NH0101', name: '道の駅なみえ'},
    { code: 'NH0113', name: 'スポーツセンター'}
  ],
  devices: [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 }
  ],
  /** サービサーURLリスト. */
  servicerApiUrls: [
    'https://nissan-mslab-dev-na.com/reserve',
    'http://localhost:8081'
  ],

  /** 時刻表URL. */
  timeScheduleUrl: '/v2/image?name=system/opening_hours.txt',

  /** 住所リストURL. */
  stationCsvUrl: '/v2/image?name=stop_point/data.csv',

  addressCsvUrl: './assets/data.csv',

  /** プレイリストのパス */
  playlistUrlPath: '/v2/image?name=system/playlist',

  /* マップの切り替え.
  *
  * - none : マップ使用しない
  * - osm : OpenStreetMap
  * - google : Google Map
  *    `index.html` に `<script src="https://maps.googleapis.com/maps/api/js?key=${GoogleMapApikey}" async defer></script>` を指定してください。
  */
  map: 'google',
  /** デフォルト乗車人数. */
  defaultRideCount: 1,
  /** 最小乗車人数. */
  minRideCount: 1,
  /** 最大乗車人数. */
  maxRideCount: 2,
  /** デフォルト荷物数. */
  defaultCargoCount: 1,
  /** 最小荷物数. */
  minCargoCount: 1,
  /** 最大荷物数. */
  maxCargoCount: 3,
  /** 必須乗車地付近にいるかを判断するインターバル(ミリ秒). */
  requiredStationNearInterval: 30000,
  /** 歩行速度（単位 : km/h）デフォルト : 4.8km/h（時速） ※分速の場合、80m/min */
  walkVelocity: 4.8,


  /** オートコンプリートの有効化. */
  isEnabledAutocomplete: false,
  /** BLEの有効. */
  isEnabledBle: false,
  /** ServiceWorkerModule の登録.（PWA 実行時に true） */
  serviceWorkerRegister: true,

  /** 地図初期表示（緯度）. */
  mapInitialStateLat: 37.496098500,
  /** 地図初期表示（経度）. */
  mapInitialStateLng: 141.000272600,
  /** 地図初期表示（ズーム）. */
  mapInitialStateZoom: 20,

  csvLoader: null,

  DoStationCodes: null,

  consttables : {
    userStations: null,
    timeSchedule: null,
    datacsv: null,
    puStations: null,
    nextLoadDate: 0
  },
 
  stationtables : {
    DoStationCodes:null,
    stationSession:null
  },
  // doStationCodeを取得する際に使用するユーザーID　N070
  APIaccessUserId : 150,

  currentGuest : null,

  pageReloading : false,

  staticTableLoaded:false,

  playlistInfo : {
    /* 最終更新日 */
    updatedAt: 0,
  },

  //// M2D:
  constTables : {
    userStations:null,
    timeSchedule:null,
    dataCsv:null,
    nextLoadDate:0
  },
  stationTables : {
    DoStationCodes:null,
    stationSession:null,
    assignName:'',
    displayName:'',
  }
};
