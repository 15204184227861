import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { StaticTableLoader } from '../../app/services/StaticTableLoader';
import { UserV2ServiceProvider } from './user-v2-service';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { StationForSearch } from '../../app/services/servicer/models/stationForSearch';
import { StationV2ServiceProvider } from './station-v2-service';
import { UpdatesAlertService } from '../../app/services/updates-alert.service';
import { RetryGuestLoginFlagService } from '../../app/services/retry-guest-login-flag.service';
import { StorageWrapperService } from '../../app/services/storage-wrapper.service';

/**
 * キャッシュ更新サービスプロバイダー.
 */
@Injectable()
export class CacheUpdateService {

  // 24時間（ミリ秒）
  private readonly ONE_DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

  // 現在設定されている更新間隔
  private currentInterval = this.ONE_DAY_IN_MILLISECONDS;

  // 更新完了を検知するトリガー
  public completeSubject = new Subject<boolean>();

  constructor(
    private userV2Service: UserV2ServiceProvider,
    private staticTable: StaticTableLoader,
    public stationV2Service: StationV2ServiceProvider,
    private toast: ToastController,
    private updateAlert: UpdatesAlertService,
    private retryGuestLoginFlagService: RetryGuestLoginFlagService,
    private storageWrapperService: StorageWrapperService
  ) {
    this.staticTable = new StaticTableLoader(this.userV2Service, this.stationV2Service, this.retryGuestLoginFlagService, this.storageWrapperService);
  }

  /**
   * 更新間隔を設定する（翌日0時）
   *
   */
  public setTimerForMidnight() {
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const initialDelay = tomorrow.getTime() - now.getTime();

    this.currentInterval = this.ONE_DAY_IN_MILLISECONDS;
    timer(initialDelay, this.currentInterval).subscribe(() => {
      this.updateAlert.checkForUpdateVersion().then(() => {
        this.reloadServerCache();
      });
    });
  }

  /**
   * サーバーキャッシュ更新処理
   *
   */
  public reloadServerCache(): void {
    //設定中の駅コードを退避
    const orgStationCode = environment.setting.stationCode;

    this.staticTable.initStationList().subscribe(async (result: boolean) => {
      if (result) {
        if (orgStationCode) {
          const targetStation = environment.consttables.userStations.find((station: StationForSearch) => station.code === orgStationCode);
          this.staticTable.getStationTable(targetStation).subscribe(async (saveResult: boolean) => {
            if (saveResult) {
              (await this.toast.create({
                message: '読み込み成功',
                position: 'top',
                duration: 2000
              })).present();

              // サーバーキャッシュ更新完了を通知
              this.completeSubject.next(saveResult);
            } else {
              (await this.toast.create({
                message: '読み込み失敗',
                position: 'top',
                duration: 2000
              })).present();
            }
          });
          //駅コード未設定の場合は、何も処理せず読み込み成功と表示
        } else {
          (await this.toast.create({
            message: '読み込み成功',
            position: 'top',
            duration: 2000
          })).present();

          // サーバーキャッシュ更新完了を通知するが、駅コードが未設定のためfalseを返す
          this.completeSubject.next(false);
        }
      } else {
        if (this.retryGuestLoginFlagService.getFlag()) {
          this.completeSubject.next(false);
          return;
        }
        (await this.toast.create({
          message: '読み込み失敗',
          position: 'top',
          duration: 2000
        })).present();
      }
    });
  }
}
