import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PlaylistParameter } from './models/playlist-parameter';
import { PlaylistResponse } from './models/playlist-response';
import { Playlist } from './models/playlist';

import { environment } from '../../environments/environment';


/**
 * プレイリストプロバイダー.
 */
@Injectable()
export class PlaylistServiceProvider {

  validImageUrls: string[] = [];

  constructor(
    public http: HttpClient
  ) {
  }

  /**
   * キャッシュ画像取得
   *
   * @param url string
   * @returns Promise<string>
   */
  async getCacheImageUrl(url: string): Promise<string> {
    
    const dir = this.getDir();

    const cache = await caches.open(dir);
    const cached = await cache.match(url);
    if (!cached) {
      return await new Promise<string>( resolve => resolve("") );
    }
    const blob = await cached.blob();
    const blobUrl = await URL.createObjectURL(blob);
    const src = `url(${blobUrl})`;
    URL.revokeObjectURL(src);
    return await new Promise<string>( resolve => resolve(src) );
  }

  /**
   * プレイリストをキャッシュから取得する
   * ホームでのプレイリストと画像を取得する。
   *
   * @param parameter PlaylistsV2Parameter
   * @returns Observable<PlaylistsV2Response>
   */
  async getCachePlaylist(parameter: PlaylistParameter): Promise<PlaylistResponse> {

    const dir = this.getDir();
    const url = this.getUrl(`/${parameter.playlistID}.json`);
    
    // キャッシュから取得
    const cache = await caches.open(dir);
    const playlistCached = await cache.match(url);
    if (!playlistCached) { throw 'cache fail' }

    const playlist = await playlistCached.text();
    const playlist_obj = JSON.parse(playlist);
    const imageFileNemes = playlist_obj.flatMap((v) => {
      const now_millis = Date.now();
      const now_sec = Math.floor(now_millis / 1000);
      //enddateが0の場合は未設定、未設定の場合無制限とする
      if(now_sec >= v.startdate && ( now_sec <= v.enddate || 0 == v.enddate)){
        return v.filename
      } else {
        //利用期間外の場合はプレイリストから除外する。
        return [];
      }
    });

    const imageUrls = imageFileNemes.map((v) => this.getImageUrl(`/${v}`));
    const imagesResponses = await this.matchCacheFiles(dir, imageUrls);
    if (imageUrls.length != imagesResponses.length) { throw 'images fail' }

    const response = <PlaylistResponse>{ result: 1, playlistID: parameter.playlistID, playlists: imageUrls.flatMap((v) => <Playlist>{imgUrl: v}) };
    return await new Promise<PlaylistResponse>( resolve => resolve(response) );
  }

  /**
   * プレイリストをダウンロードし保存と不要データの削除を行う
   * ホームでのプレイリストと画像を取得する。
   *
   * @param parameter PlaylistsV2Parameter
   * @returns Observable<PlaylistsV2Response>
   */
  async updatePlaylist(parameter: PlaylistParameter): Promise<PlaylistResponse> {
    await this.deletePlaylist();

    const dir = this.getDir();
    const url = this.getUrl(`/${parameter.playlistID}.json`);

    // ネットから取得
    const playlistResponses = await this.fetchFiles([url]);
    if (playlistResponses.length == 0) { throw 'playlist fail' }

    const playlist = await playlistResponses[0].clone().text();
    const playlist_obj = JSON.parse(playlist)
    const imageFileNemes = playlist_obj.flatMap((v) => {
      const now_millis = Date.now();
      const now_sec = Math.floor(now_millis / 1000);
      if(now_sec >= v.startdate && ( now_sec <= v.enddate || 0 == v.enddate)){
        return v.filename
      } else {
        //利用期間外の場合はプレイリストから除外する。
        return [];
      }
    });

    const imageUrlsTmp = imageFileNemes.map((v) => this.getImageUrl(`/${v}`));
    this.validImageUrls = [];
    
    await this.checkImageUrls(imageUrlsTmp)
      .then(() => {
      })
      .catch(error => {
          throw 'playlist fail';
      });
    const imageUrls = this.validImageUrls;

    const imagesResponses = await this.fetchFiles(imageUrls);
    if (imageUrls.length != imagesResponses.length) { throw 'images fail' }

    await this.deletePlaylist();

    const responseAll = [...playlistResponses, ...imagesResponses];
    await this.addCacheFiles(dir, responseAll);

    const response = <PlaylistResponse>{ result: 1, playlistID: parameter.playlistID, playlists: imageUrls.flatMap((v) => <Playlist>{imgUrl: v}) };
    return await new Promise<PlaylistResponse>( resolve => resolve(response) );
  }

  /**
   * ファイルの存在チェック
   * Fix02i:
   */
  async checkImageUrls(imageUrls: string[]) {

    for (const url of imageUrls) {
      try {
        const response = await fetch(url, { method: 'HEAD' });
        if (response.ok) {
            this.validImageUrls.push(url);
        } else {
            this.validImageUrls.push(url);
        }
      } catch (error) {
        throw 'playlist fail';
      }
    }
  }
 
  /**
   * プレイリストの削除
   */
  async deletePlaylist() {

    const dir = this.getDir();
    const cache = await caches.open(dir);
    const requests = await cache.keys();
    return await Promise.all(requests.map( (request) => cache.delete(request) ));
  }

  /**
   * ファイルをダウンロードする
   *
   * @param urls string[]
   */
  async fetchFiles(urls: string[]) {

    const load = async (url: string) => {
      return await fetch(url).then((response) => {
        if (!response.ok) {
          return response;
        }
        return response
      });
    }
    
    return await Promise.all(urls.map(load)).then((v) => { return v }).catch(() => { const v: Response[] = []; return v }); 
  }

  /**
   * キャッシュを保存する 
   * @param dir キャッシュディレクトリ
   * @param urls 
   * @returns boolean
   */
  async addCacheFiles(dir: string, responses: Response[]) {

    const cache = await caches.open(`${dir}`);
    const save = async (response: Response) => {
        return cache.put(response.url, response);
    };
    
    return await Promise.all(responses.map(save)).then((v) => { return v }).catch(() => { const v: Response[] = []; return v }); 
  }

  /**
   * キャッシュを取得する 
   * @param dir キャッシュディレクトリ
   * @param urls 
   * @returns Response[]
   */
  async matchCacheFiles(dir: string, urls: string[]) {

    const cache = await caches.open(`${dir}`);
    const match = async (url: string) => {
        const cached = await cache.match(url);
        if (cached !== undefined) {
          return cached
        } else {
          return [];
        }
    };
    
    return await Promise.all(urls.map(match)).then((v) => { return v }).catch(() => { const v: Response[] = []; return v }); 
  }

  /**
   * 
   * @returns キャッシュDir
   */
  private getDir(): string {
    return `slide`
  }

  /**
   * URL取得.
   * 
   * @param path パス
   * @returns URL
   */
  private getUrl(path: string): string {
    return environment.setting.servicerApiUrl + environment.playlistUrlPath + path;
  }
  /**
   * 画像URL取得.
   * 
   * @param path パス
   * @returns URL
   */
  private getImageUrl(path: string): string {
    return  environment.setting.resourceS3BucketUrl + "/imgs" + path;
  }
}
